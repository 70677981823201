/**
 *
 * Conditionnal form error
 *
 */

$(document).ready(function() {
  var $errors = $('.c-form__feedback--error');

  if ($errors.length > 0) {
    var firstErrorId = $errors.first().attr( "id").replace('-error','');
    var $firstElementWithError = $('#' + firstErrorId);

    if($firstElementWithError.prop("tagName").toLowerCase() == "fieldset") {
      $firstElementWithError.find('input').focus();
    }else {
      $firstElementWithError.focus();
    }
  } else {
  }
});
