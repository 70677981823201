/**
 *
 * Slider with wiping animation
 *
 */

 (function() {
  var $wipers = $('.js-wiper');
  var processing = false;

  $wipers.each(function (index, wiper) {
    var $wiper = $(wiper);
    var $items = $wiper.find('.js-wiper-item');
    var $buttons = $wiper.find('.js-wiper-navigation');
    var current = 0;
    var count = $items.length;
    var duration = css_time_to_milliseconds($items.first().children().first().css('animation-duration')) - 20;

    var updateItem = function ($old, $new) {
      processing = true;
      $old.addClass('is-hiding');
      var timer = setTimeout(function() {
        hideItem($old);
        $old.removeClass('is-hiding');
        showItem($new);
        processing = false;
        clearTimeout(timer);
      }, duration);
    };

    var hideItem = function ($item) {
      $item.attr('aria-hidden', 'true');
      return $item;
    }

    var showItem = function ($item) {
      $item.attr('aria-hidden', 'false');
    }

    $buttons.on('click', function (event) {
      var tmp = current;
      var $button = $(event.currentTarget);
      var delta = parseInt($button.data('index'));

      if(delta < 0) {
        $wiper.attr('data-reverse', true);
      } else {
        $wiper.attr('data-reverse', false);
      }

      if(!processing) {
        current = current + delta;
        current = current < 0 ? count-1 : current;
        current = current > count-1 ? 0 : current;
        updateItem($items.eq(tmp), $items.eq(current));
      }
    });
  });
})();
