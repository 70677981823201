/* Need window.requestanimationframe/requestanimationframe.js */

/**
 *
 * Slideshow with logo animation and navigation
 *
 */

(function() {
  var $sliders = $('.js-slider');
  var processing = false;

  $sliders.each(function (index, slider) {
    var $slider = $(slider);
    var $container = $slider.find('.js-slider-items');
    var $skeleton = $slider.find('.js-slider-skeleton');
    var $items = $slider.find('.js-slider-item');
    var $buttons = $slider.find('.js-slider-navigation');
    var $dots = $slider.find('.js-slider-dot');
    var $motionControl = $slider.find('.c-slider__motion-control');
    var $images = $slider.find('.js-slider-image');
    var current = 0;
    var count = $items.length;
    var time = Math.floor(Date.now());
    var transition = css_time_to_milliseconds($items.first().css('transition-duration'));
    var duration = 6000;
    var height = 0;
    var pause = false;
    //var test;

    var updateItem = function (prev, next) {
      var $prevSlide = $items.eq(prev);
      var $nextSlide = $items.eq(next);
      var $prevImage = $images.eq(prev);
      var $nextImage = $images.eq(next);
      processing = true;
      $slider.addClass('is-transitioning');
      $prevSlide.attr('aria-hidden', "true");
      $prevImage.attr('aria-hidden', "true");
      $nextSlide.attr('aria-hidden', "false");
      $nextImage.attr('aria-hidden', "false");
      $dots.removeClass('is-active').eq(current).addClass('is-active');
      $dots.removeAttr( "aria-current").eq(current).attr( "aria-current", true );

      var timer = setTimeout(function() {
        $slider.removeClass('is-transitioning');
        processing = false;
        //updateHeight();
        clearTimeout(timer);
      }, transition);

      time = Math.floor(Date.now());
    };

    var updateHeight = function () {
      height = $skeleton.outerHeight();
      $items.each(function(index, item) {
        var $item = $(item);
        $item.css('height', 'auto');
       height = $item.outerHeight() > height ? $item.outerHeight() : height;
      });

      $container.css('height', height + 150);
    };

    var changeSlide = function (delta) {
      var tmp = current;

      if(delta < 0) {
        $slider.attr('data-reverse', true);
      } else {
        $slider.attr('data-reverse', false);
      }

      if(!processing) {
        current = current + delta;
        current = current < 0 ? count-1 : current;
        current = current > count-1 ? 0 : current;
        updateItem(tmp, current);
      }
    };

    var update = debounce(function() {
      updateHeight();
    }, 250);

    var init = function () {
      $dots.first().addClass('is-active');
      $dots.first().attr( "aria-current", true );
      updateHeight();

      (function loop() {
        if (pause) {
        } else {
          requestAnimationFrame(loop);

          var currentTime = Math.floor(Date.now());
          delta = currentTime - time;

          if(delta >= duration) {
            changeSlide(1);
          }
        }

      })();
    };

    $buttons.on('click', function (event) {
      var $button = $(event.currentTarget);
      var delta = parseInt($button.data('index'));

      changeSlide(delta);
    });

    $dots.on('click', function (event) {
      var $dot = $(event.currentTarget);
      if(!$dot.hasClass('is-active')) {
        var index = parseInt($dot.data('index'));
        var delta = index - current;

        changeSlide(delta);
      }
    });

    $motionControl.on('click', function (event) {
      if(pause) {
        pause = false;
        $motionControl.html('<svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.9688 22.75C6.04875 22.75 1.21875 17.93 1.21875 12C1.21875 6.07 6.04875 1.25 11.9688 1.25C17.8888 1.25 22.7188 6.07 22.7188 12C22.7188 17.93 17.8988 22.75 11.9688 22.75ZM11.9688 2.75C6.86875 2.75 2.71875 6.9 2.71875 12C2.71875 17.1 6.86875 21.25 11.9688 21.25C17.0688 21.25 21.2188 17.1 21.2188 12C21.2188 6.9 17.0688 2.75 11.9688 2.75Z" fill="currentColor"/><path d="M10.008 15.95H8.70805C7.78805 15.95 7.24805 15.42 7.24805 14.53V9.47005C7.24805 8.58005 7.79805 8.05005 8.70805 8.05005H9.99805C10.918 8.05005 11.458 8.58005 11.458 9.47005V14.53C11.468 15.42 10.918 15.95 10.008 15.95ZM8.74805 14.45H9.96805V9.55005H8.75805L8.74805 14.45Z" fill="currentColor"/><path d="M15.2891 15.95H13.9991C13.0791 15.95 12.5391 15.42 12.5391 14.53V9.47005C12.5391 8.58005 13.0891 8.05005 13.9991 8.05005H15.2891C16.2091 8.05005 16.7491 8.58005 16.7491 9.47005V14.53C16.7491 15.42 16.1991 15.95 15.2891 15.95ZM14.0291 14.45H15.2491V9.55005H14.0391L14.0291 14.45Z" fill="currentColor"/></svg><span class="c-link__text">Pause</span>');

        (function loop() {
          if (pause) {
          } else {
            requestAnimationFrame(loop);

            var currentTime = Math.floor(Date.now());
            delta = currentTime - time;

            if(delta >= duration) {
              changeSlide(1);
            }
          }

        })();
      }else {
        pause = true;
        $motionControl.html('<svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.9688 22.75C6.04875 22.75 1.21875 17.93 1.21875 12C1.21875 6.07 6.04875 1.25 11.9688 1.25C17.8888 1.25 22.7188 6.07 22.7188 12C22.7188 17.93 17.8988 22.75 11.9688 22.75ZM11.9688 2.75C6.86875 2.75 2.71875 6.9 2.71875 12C2.71875 17.1 6.86875 21.25 11.9688 21.25C17.0688 21.25 21.2188 17.1 21.2188 12C21.2188 6.9 17.0688 2.75 11.9688 2.75Z" fill="currentColor"/><path d="M10.5583 16.99C10.1183 16.99 9.69828 16.88 9.32828 16.67C8.46828 16.17 7.98828 15.19 7.98828 13.91V10.56C7.98828 9.27999 8.45828 8.29999 9.31828 7.79999C10.1783 7.29999 11.2683 7.37999 12.3783 8.01999L15.2783 9.68999C16.3883 10.33 16.9983 11.23 16.9983 12.23C16.9983 13.22 16.3883 14.13 15.2783 14.77L12.3783 16.44C11.7583 16.81 11.1283 16.99 10.5583 16.99ZM10.5583 8.96999C10.3783 8.96999 10.2083 9.00999 10.0783 9.08999C9.69828 9.30999 9.48828 9.83999 9.48828 10.56V13.91C9.48828 14.62 9.69828 15.16 10.0783 15.37C10.4483 15.59 11.0183 15.5 11.6383 15.15L14.5383 13.48C15.1583 13.12 15.5083 12.67 15.5083 12.24C15.5083 11.81 15.1483 11.36 14.5383 11L11.6383 9.32999C11.2383 9.08999 10.8683 8.96999 10.5583 8.96999Z" fill="currentColor"/></svg><span class="c-link__text">Play</span>')
      }
    });

    $(window).on('resize', update);

    init();
  });
})();
