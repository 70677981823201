/**
 *
 * Navigation with subnav expansion
 *
 */

(function() {
  'use strict';

  var $document = $(document);
  var $navigation = $("#navigation");
  var $togglers = $navigation.find('.js-navigation-toggler');

  var collapseAllTogglers = function () {
    $togglers.each(function(index, toggler) {
      collapse($(toggler));
    });
  };

  var collapse = function ($toggler) {
    $toggler.attr('aria-expanded', "false");
  }

  var expand = function ($toggler) {
    $toggler.attr('aria-expanded', "true");
  }

  $togglers.each(function(index, toggler) {
    var $toggler = $(toggler);
    var $item = $toggler.parents('.js-navigation-item');
    var $target = $('#' + $toggler.attr('aria-controls'));
    var tmp = false;

    var toggle = function () {
      var state = $toggler.attr('aria-expanded') == "true";

      if(state) {
        collapse($toggler);
      } else {
        collapseAllTogglers();
        expand($toggler);
      }
    };

    $toggler.on('click', function(event) {
      toggle();
    });

    if(Modernizr.touchevents) {
      $document.on('click', function (event) {
        var state = $toggler.attr('aria-expanded') == "true";
        if(state && $navigation.attr('aria-hidden') == "false") {
          var $target = $(event.target);
          var is_inside = $target.closest('.js-navigation-item').length > 0;

          if (!is_inside) {
            toggle();
          }
        }
      });
     } else {
      $item.on('mouseenter', function(event) {
        toggle();
      });

      $item.on('mouseleave', function(event) {
        toggle();
      });

      $document.on('keyup', function(event) {
        var is_inside = $(document.activeElement).closest('.js-navigation-item').is($item);

        if (!is_inside && tmp) {
          var state = $toggler.attr('aria-expanded') == "true";
          if(state) {
            toggle();
          }
        }

        tmp = is_inside;
      });
     }
  });

})();
