/* Need intersection-observer/intersection-observer.js */
/* Need countup.js/dist/countUp.js */


/**
 *
 * Add animation to number of block component at first display in viewport
 *
 */

(function() {

  var options = {
  useEasing: true,
  useGrouping: true,
  separator: ' ',
  decimal: ',',
  };

  var observer = new IntersectionObserver(function (observables) {
    var $observables = $(observables);

    $observables.each(function (index, observable) {

      var $observable = $(observable.target),
          $text = $observable.find('.js-block-number'),
          count = $observable.data('count'),
          decimal = 0;

      if(count.search(',') > -1) {
        var number = count.split(',');
        decimal = number[number.length-1].length;
      }

      count = parseFloat(count.replace(',', '.'));

      if(observable.intersectionRatio > 0.5 && !$observable.hasClass('is-showed')) {
        var countUp = new CountUp($text[0], 0, count, decimal, 3, options).start();
        $observable.addClass('is-showed');
      }

    })
  }, {
    threshold: [0.5]
  });

  $('.js-block').each(function(index, number) {
    var $number = $(number),
        $text = $number.find('.js-block-number');

    $number.data('count', $text.text());

    $text.html(0);

    observer.observe(number);
  });


})();
